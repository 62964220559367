import { localStorageService } from 'core-roblox-utilities';
import { httpService } from 'core-utilities';
import RequestType from '../enums/RequestType';
import { newParentalRequest, broadcastParentalRequest } from '../constants/urlConstants';

type TSettingDetail = {
  settingName: string;
  newValue: unknown;
};

type TUpdateSettingRequestDetails = {
  updateUserSettingRequestDetails: TSettingDetail;
};

type TRequestDetails = TUpdateSettingRequestDetails | Record<string, unknown>;

type TSendRequestToNewParentRequestBody = {
  email: string;
  requestType: RequestType;
  requestDetails?: TRequestDetails;
};

type TSendRequestToAllParentsRequestBody = {
  requestType: RequestType;
  requestDetails?: TRequestDetails;
};

type TNewParentalRequestParams = {
  email: string;
  requestType: RequestType;
  requestDetails?: Record<string, unknown>;
};

type TParentalRequestResponse = {
  lockedUntil: string;
  sessionId: string;
};

type TBoadcastParentalRequestParams = {
  requestType: RequestType;
  requestDetails?: Record<string, unknown>;
};

// TODO: ACCMAN-1972: Remove this conditional logic once backend api is unified
// for all request types.
const buildRequestDetails = (
  requestType: RequestType,
  requestParams: Record<string, unknown>
): TRequestDetails => {
  switch (requestType) {
    case RequestType.UpdateUserSetting: {
      const updateUserSettingRequestDetails = Object.entries(requestParams).reduce(
        (_acc, [settingName, newValue]) => {
          return { settingName, newValue };
        },
        {} as TSettingDetail
      );
      return { updateUserSettingRequestDetails };
    }

    default:
      return requestParams;
  }
};

const parentalRequestService = {
  sendRequestToNewParent: async (
    params: TNewParentalRequestParams
  ): Promise<TParentalRequestResponse> => {
    const urlConfig = { url: newParentalRequest, withCredentials: true };
    const { requestDetails } = params;
    const requestBody: TSendRequestToNewParentRequestBody = {
      ...params,
      requestDetails: buildRequestDetails(params.requestType, requestDetails)
    };

    const response = await httpService.post<TParentalRequestResponse>(urlConfig, requestBody);
    if (requestDetails) {
      const settingName = Object.keys(requestDetails)[0];
      localStorageService.setLocalStorage(
        `Roblox.ParentalRequest.${settingName}CooldownExpirationTime`,
        response.data.lockedUntil
      );
    }
    return response.data;
  },
  sendRequestToAllParents: async (
    params: TBoadcastParentalRequestParams
  ): Promise<TParentalRequestResponse> => {
    const urlConfig = { url: broadcastParentalRequest, withCredentials: true };
    const { requestDetails } = params;

    const requestBody: TSendRequestToAllParentsRequestBody = {
      ...params,
      requestDetails: buildRequestDetails(params.requestType, requestDetails)
    };

    const response = await httpService.post<TParentalRequestResponse>(urlConfig, requestBody);
    if (requestDetails) {
      const settingName = Object.keys(requestDetails)[0];
      localStorageService.setLocalStorage(
        `Roblox.ParentalRequest.${settingName}CooldownExpirationTime`,
        response.data.lockedUntil
      );
    }
    return response.data;
  }
};

export default parentalRequestService;
